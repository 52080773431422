<template>
	<!-- <div class="banner">
		<img alt="Banner" src="/banner-motul-web.jpg"/>
	</div> -->
	<Error :errors="errors"/>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">

			<div class="surface-section px-4 py-8 md:px-6 lg:px-8">
				<div class="text-700 text-center">
					<div class="text-blue-600 font-bold mb-3">&nbsp;SELAMAT DATANG DI</div>
					<div class="text-900 font-bold text-5xl mb-3">Web Report - {{appsName}}</div>
				</div>
			</div>
		</div>
	</div>

	<div class="grid mt-2 mb-2">
		<div class="col-12 md:col-4 lg:col-4">
			<div class="card ">
				<h4>CHECK IN</h4>
				<CheckInChart />
			</div>
		</div>
		<div class="col-12 md:col-8 lg:col-8">
			<div class="card ">
				<h4>ATTENDANCE SUMMARY BY POSITION</h4>
				<AttendanceSummaryByPosition />
			</div>
		</div>
	</div>

	<div class="grid mt-2 mb-2">
		<div class="col-12">
			<div class="card ">
				<h4>ATTENDANCE BY POSITION</h4>
				<AttendanceByPositionChart />
			</div>
		</div>
	</div>

	<div class="grid mt-2 mb-2">
		<div class="col-12">
			<div class="card ">
				<h4>EMPLOYEE CHECK IN</h4>
				<MapEmployeeCheckin />
			</div>
		</div>
	</div>

	<div class="grid mt-2 mb-2" v-if="user?.level_id !== 4 && user?.level_id !== 5 && user?.level_id !== 9">
		<div class="col-12">
			<div class="card ">
				<h4>TRACKING ROUTE</h4>
				<MapTrackingRoute />
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import CheckInChart from '../components/dashboard/CheckInChart.vue';
import MapEmployeeCheckin from '../components/dashboard/MapEmployeeCheckin.vue';
import MapTrackingRoute from '../components/dashboard/MapTrackingRoute.vue';
import AttendanceSummaryByPosition from '../components/dashboard/AttendanceSummaryByPosition.vue';
import AttendanceByPositionChart from '../components/dashboard/AttendanceByPositionChart.vue';
import Error from '../components/Error.vue';

export default {
	components: {
		'CheckInChart': CheckInChart,
		'MapEmployeeCheckin': MapEmployeeCheckin,
		'MapTrackingRoute': MapTrackingRoute,
		'AttendanceSummaryByPosition': AttendanceSummaryByPosition,
		'AttendanceByPositionChart': AttendanceByPositionChart,
		'Error': Error,
	},
	computed:{
        ...mapGetters(['errors']),
		...mapGetters("auth", ["user"]),
    },
	data() {
		return {		
			appsName: process.env.VUE_APP_APPS_NAME,
		}
	},
}
</script>
<style>
	.banner {
		width: 100%;
		/* height: 200px; */
		background-color: #f0f0f0;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.banner img {
		max-width: 100%;
		max-height: 100%;
		object-fit: contain;
	}
</style>